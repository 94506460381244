import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { getGlobalAnalytics, exportClientsData } from '../utils/api';
import '../styles/GlobalAnalytics.css';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const GlobalAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState({
    paymentsByOffice: [],
    summary: {
      totalRevenue: 0,
      totalProfit: 0,
      activeClients: 0,
      averageCheck: 0
    },
    monthlyRevenue: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);

  useEffect(() => {
    const loadAnalyticsData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await getGlobalAnalytics({
          startDate: dateRange[0].format('YYYY-MM-DD'),
          endDate: dateRange[1].format('YYYY-MM-DD')
        });
        
        if (response.error) {
          throw new Error(response.error);
        }

        setAnalyticsData({
          paymentsByOffice: response.paymentsByOffice || [],
          summary: response.summary || {
            totalRevenue: 0,
            totalProfit: 0,
            activeClients: 0,
            averageCheck: 0
          },
          monthlyRevenue: response.monthlyRevenue || []
        });
      } catch (err) {
        console.error('Ошибка загрузки аналитики:', err);
        setError(err.message || 'Ошибка загрузки данных. Попробуйте обновить страницу.');
      } finally {
        setLoading(false);
      }
    };

    loadAnalyticsData();
  }, [dateRange]);

  const handleExport = async () => {
    try {
      setExportLoading(true);
      await exportClientsData();
    } catch (error) {
      console.error('Ошибка экспорта:', error);
      setError('Ошибка при экспорте: ' + error.message);
    } finally {
      setExportLoading(false);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      minimumFractionDigits: 2
    }).format(amount || 0);
  };

  const formatDate = (dateString) => {
    try {
      const options = { year: 'numeric', month: 'long' };
      return new Date(dateString).toLocaleDateString('ru-RU', options);
    } catch {
      return dateString;
    }
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange([dates[0], dates[1]]);
    }
  };

  // Исправленный расчет максимальной выручки
  const maxRevenue = Math.max(
    ...(analyticsData.monthlyRevenue.map(i => Number(i.revenue)) || [0]), 
    1
  );

  return (
    <div className="global-analytics">
      <header className="analytics-header">
        <h2>Глобальная аналитика</h2>
        <div className="controls">
          <RangePicker
            value={dateRange}
            onChange={handleDateChange}
            format="DD.MM.YYYY"
            disabled={loading}
            style={{ width: 250, marginRight: 10 }}
          />
          
          <button 
            onClick={handleExport} 
            className="export-button"
            disabled={exportLoading || loading}
          >
            {exportLoading ? 'Экспорт...' : 'Экспорт базы клиентов'}
          </button>
        </div>
      </header>

      <div className="analytics-content">
        {loading ? (
          <div className="loading">
            <div className="spinner"></div>
            <h3>Загрузка данных...</h3>
          </div>
        ) : error ? (
          <div className="error">
            <h3>{error}</h3>
            <button 
              onClick={() => window.location.reload()} 
              className="retry-button"
            >
              Обновить страницу
            </button>
          </div>
        ) : (
          <>
            {/* Общая сводка */}
            <div className="summary-cards">
              <div className="summary-card">
                <h3>Общая выручка</h3>
                <p>{formatCurrency(analyticsData.summary.totalRevenue)}</p>
              </div>
              <div className="summary-card">
                <h3>Прибыль</h3>
                <p>{formatCurrency(analyticsData.summary.totalProfit)}</p>
              </div>
              <div className="summary-card">
                <h3>Клиентов</h3>
                <p>{analyticsData.summary.activeClients}</p>
              </div>
              <div className="summary-card">
                <h3>Средний чек</h3>
                <p>{formatCurrency(analyticsData.summary.averageCheck)}</p>
              </div>
            </div>

            {/* Платежи по офисам */}
            <div className="chart-container">
              <h3>Платежи по офисам за период: {dateRange[0].format('DD.MM.YYYY')} - {dateRange[1].format('DD.MM.YYYY')}</h3>
              {analyticsData.paymentsByOffice.length > 0 ? (
                <div className="offices-grid">
                  {analyticsData.paymentsByOffice.map((office) => (
                    <div key={office.officeId} className="office-card">
                      <h4>{office.officeId}</h4>
                      <div className="office-stats">
                        <div className="stat-row">
                          <span>Платежей:</span>
                          <span>{office.paymentCount}</span>
                        </div>
                        <div className="stat-row">
                          <span>Сумма:</span>
                          <span>{formatCurrency(office.totalAmount)}</span>
                        </div>
                        <div className="stat-row">
                          <span>Средний чек:</span>
                          <span>
                            {formatCurrency(
                              office.paymentCount > 0 
                                ? office.totalAmount / office.paymentCount 
                                : 0
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-data">
                  <p>Нет данных о платежах за выбранный период</p>
                </div>
              )}
            </div>

            {/* Ежемесячная выручка */}
            <div className="chart-container">
              <h3>Ежемесячная выручка</h3>
              {analyticsData.monthlyRevenue.length > 0 ? (
                <div className="revenue-chart">
                  {analyticsData.monthlyRevenue.map((item) => (
                    <div key={item.month} className="revenue-item">
                      <div className="revenue-month">
                        {formatDate(item.month)}
                      </div>
                      <div className="revenue-bar-container">
                        <div 
                          className="revenue-bar" 
                          style={{
                            width: `${((Number(item.revenue) || 0) / maxRevenue) * 100}%`
                          }}
                        >
                          <span className="revenue-amount">
                            {formatCurrency(item.revenue)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-data">
                  <p>Нет данных о выручке за выбранный период</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GlobalAnalytics;