import axios from 'axios';

const API_URL = 'https://skyneocrm.ru/api';

// Авторизация
export const login = async ({ username, password }) => {
  try {
    const { data } = await axios.post(`${API_URL}/login`, { username, password });
    localStorage.setItem('token', data.token);
    localStorage.setItem('role', data.role);
    localStorage.setItem('managerId', data.managerId);
    return { success: true, role: data.role };
  } catch (error) {
    console.error('Ошибка входа:', error);
    return { success: false, error: error.response?.data?.message || 'Ошибка входа' };
  }
};

export const register = async ({ username, password, role }) => {
  try {
    await axios.post(`${API_URL}/register`, { username, password, role });
    return await login({ username, password });
  } catch (error) {
    console.error('Ошибка регистрации:', error);
    return { success: false, error: error.response?.data?.message || 'Ошибка регистрации' };
  }
};

// Клиенты
export const getAllClients = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/clients`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка получения списка клиентов:', error);
    throw error;
  }
};

export const getClientById = async (id) => {
  try {
    const { data } = await axios.get(`${API_URL}/clients/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка получения клиента:', error);
    throw error;
  }
};

export const createClient = async (clientData) => {
  try {
    const { data } = await axios.post(`${API_URL}/clients`, clientData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка создания клиента:', error);
    throw error;
  }
};

export const updateClient = async (id, clientData) => {
  try {
    const { data } = await axios.put(`${API_URL}/clients/${id}`, clientData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка обновления клиента:', error);
    throw error;
  }
};

export const updateClientNotes = async (id, notes) => {
  try {
    const { data } = await axios.put(`${API_URL}/clients/${id}/notes`, { notes }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка обновления заметок:', error);
    throw error;
  }
};

export const deleteClient = async (id) => {
  try {
    await axios.delete(`${API_URL}/clients/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
  } catch (error) {
    console.error('Ошибка удаления клиента:', error);
    throw error;
  }
};

// Платежи
export const getPaymentsByClient = async (clientId) => {
  try {
    const { data } = await axios.get(`${API_URL}/clients/${clientId}/payments`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка при получении платежей клиента:', error);
    throw error;
  }
};

export const addPayment = async (paymentData) => {
  try {
    const { data } = await axios.post(`${API_URL}/payments`, {
      ...paymentData,
      actualDate: paymentData.actualDate || new Date().toISOString()
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка добавления платежа:', error);
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

export const updatePaymentStatus = async (paymentId, statusData) => {
  if ((statusData.paymentMethod === 'Наличные' || statusData.paymentMethod === 'Расчетный счет') && !statusData.date) {
    throw new Error('Для способа оплаты "Наличные" или "Расчетный счет" необходимо указать дату платежа');
  }

  if (statusData.date && new Date(statusData.date) > new Date()) {
    throw new Error('Дата платежа не может быть в будущем');
  }

  try {
    const { data } = await axios.put(`${API_URL}/payments/${paymentId}`, statusData, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка обновления статуса платежа:', error);
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

// Аналитика
export const getGlobalAnalytics = async (range = 'year') => {
  try {
    const response = await axios.get(`/api/analytics/global?range=${range}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
    
    // Гарантируем структуру данных
    return {
      paymentsByOffice: response.data.paymentsByOffice || [],
      summary: response.data.summary || {},
      monthlyRevenue: response.data.monthlyRevenue || [],
      paymentStats: response.data.paymentStats || { byMonth: [], byMethod: [] },
      clientStats: response.data.clientStats || { byStatus: [], newByMonth: [] }
    };
  } catch (error) {
    console.error('Error fetching analytics:', error);
    // Возвращаем структурированную ошибку
    return {
      error: error.message,
      paymentsByOffice: [],
      summary: {},
      monthlyRevenue: [],
      paymentStats: { byMonth: [], byMethod: [] },
      clientStats: { byStatus: [], newByMonth: [] }
    };
  }
};

export const getPaymentsByOffice = async (range = 'year') => {
  try {
    const { data } = await axios.get(`${API_URL}/analytics/payments-by-office`, {
      params: { range },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data.paymentsByOffice || [];
  } catch (error) {
    console.error('Ошибка при получении платежей по офисам:', error);
    throw error;
  }
};

export const exportClientsData = async () => {
  try {
    const response = await axios.get(`${API_URL}/analytics/export-clients`, {
      headers: { 
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });

    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition 
      ? contentDisposition.split('filename=')[1].replace(/"/g, '')
      : 'clients-export.xlsx';

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при экспорте данных:', error);
    throw error;
  }
};

export const exportClientData = async (clientId) => {
  try {
    const response = await axios.get(`${API_URL}/analytics/export-client/${clientId}`, {
      headers: { 
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    });

    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition 
      ? contentDisposition.split('filename=')[1].replace(/"/g, '')
      : `client-${clientId}-export.xlsx`;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при экспорте данных клиента:', error);
    throw error;
  }
};

// Логи изменений
export const getChangeLog = async (clientId) => {
  try {
    const { data } = await axios.get(`${API_URL}/clients/${clientId}/changelog`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Ошибка при получении логов изменений:', error);
    return [];
  }
};

// Документы
export const getClientDocuments = async (clientId) => {
  try {
    const { data } = await axios.get(`${API_URL}/clients/${clientId}/documents`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка при получении документов клиента:', error);
    throw error;
  }
};

export const uploadDocument = async (clientId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('clientId', clientId);

  try {
    const { data } = await axios.post(`${API_URL}/documents/upload`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    console.error('Ошибка загрузки документа:', error);
    throw error;
  }
};

export const downloadDocument = async (documentId) => {
  try {
    const response = await axios.get(`${API_URL}/documents/download/${documentId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];
    let filename = `document-${documentId}`;
    
    if (contentDisposition) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при скачивании документа:', error);
    throw error;
  }
};

export const deleteDocument = async (documentId) => {
  try {
    await axios.delete(`${API_URL}/documents/${documentId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
  } catch (error) {
    console.error('Ошибка при удалении документа:', error);
    throw error;
  }
};

// Файлы компании
export const uploadCompanyFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const { data } = await axios.post(`${API_URL}/company/file`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    console.error('Ошибка загрузки файла компании:', error);
    throw error;
  }
};

export const downloadCompanyFile = async () => {
  try {
    const response = await axios.get(`${API_URL}/company/file`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];
    let filename = 'company-file';
    
    if (contentDisposition) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Ошибка при скачивании файла компании:', error);
    throw error;
  }
};

export const getCompanyFileInfo = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/company/file/info`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка при получении информации о файле компании:', error);
    throw error;
  }
};

// Менеджеры
export const getAllManagers = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/managersreerofssifmwfisod`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return data;
  } catch (error) {
    console.error('Ошибка при получении списка менеджеров:', error);
    throw error;
  }
};

export const deleteManager = async (id) => {
  try {
    await axios.delete(`${API_URL}/managersreerofssifmwfisod/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
  } catch (error) {
    console.error('Ошибка при удалении менеджера:', error);
    throw error;
  }
};