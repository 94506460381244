import React, { useState, useEffect } from 'react';
import { addPayment, updatePaymentStatus } from '../utils/api';
import '../styles/PaymentCalculatorStyles.css';

function PaymentCalculator({ client, payments: initialPayments }) {
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [remainingDebt, setRemainingDebt] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showPartialPaymentDialog, setShowPartialPaymentDialog] = useState(false);
  const [showFullPaymentDialog, setShowFullPaymentDialog] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [partialAmount, setPartialAmount] = useState('');
  const [actualDate, setActualDate] = useState('');
  const [office, setOffice] = useState('');

  const offices = [
    'Трудовые резервы 38', 
    'Садовского 26', 
    'Пузакова 5', 
    'Колетвинова 6'
  ];

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (client && initialPayments) {
      calculatePayments();
    }
  }, [client, initialPayments]);

  // Новый алгоритм расчёта:
  // После каждого месяца вычисляется оставшийся долг,
  // и для текущего месяца плановая сумма равна оставшемуся долгу, делённому на число оставшихся платежей.
  // Если внесено больше, чем планово, излишек сразу уменьшает будущую сумму долга.
  const calculatePayments = () => {
    if (!client || !Array.isArray(initialPayments)) return;

    const startDate = new Date(client.contractDate);
    const totalAmount = client.contractAmount || 0;
    const prepayment = client.prepaymentAmount || 0;
    const contractTerm = client.contractTerm || 12;

    // Создаем карту платежей по месяцам
    const monthPaymentsMap = new Map();
    initialPayments.forEach(payment => {
      if (!monthPaymentsMap.has(payment.month)) {
        monthPaymentsMap.set(payment.month, []);
      }
      monthPaymentsMap.get(payment.month).push(payment);
    });

    const adjustedPayments = [];
    // Начинаем с оставшейся суммы после предоплаты
    let remaining = totalAmount - prepayment;

    for (let month = 1; month <= contractTerm; month++) {
      const remainingMonths = contractTerm - month + 1;
      // Плановая сумма для месяца – равномерное распределение оставшегося долга
      const scheduled = remaining / remainingMonths;

      const monthPayments = monthPaymentsMap.get(month) || [];
      const monthPaid = monthPayments.reduce((sum, p) => sum + Number(p.amount), 0);

      adjustedPayments.push({
        month,
        initialAmount: scheduled,
        paidAmount: monthPaid,
        status: monthPaid >= scheduled ? 'paid' : monthPaid > 0 ? 'partial' : 'unpaid'
      });

      // Если в месяце перевыплата – она сразу уменьшает оставшийся долг
      const overpayment = Math.max(0, monthPaid - scheduled);
      // Фиксированная плановая сумма списывается, а излишек уменьшает долг дополнительно
      remaining = remaining - scheduled - overpayment;
      if (remaining < 0) remaining = 0;
    }

    // Формирование графика для отображения
    const schedule = adjustedPayments.map(payment => {
      const dueDate = new Date(startDate);
      dueDate.setMonth(startDate.getMonth() + payment.month);
      const monthPayments = monthPaymentsMap.get(payment.month) || [];
      const latestPayment = monthPayments[monthPayments.length - 1];

      return {
        month: payment.month,
        date: dueDate.toISOString(),
        actualDate: latestPayment?.actualDate || null,
        initialAmount: payment.initialAmount,
        paidAmount: payment.paidAmount,
        status: payment.status === 'paid'
          ? 'Оплачено'
          : payment.status === 'partial'
          ? 'Частично оплачено'
          : 'Не оплачено',
        office: latestPayment?.office || '—',
        paymentMethod: client.paymentType,
        paymentId: latestPayment?.id,
        remainingForMonth: Math.max(0, payment.initialAmount - payment.paidAmount)
      };
    });

    // Общая сумма оплаченных платежей
    let totalPaidCalc = 0;
    for (let month = 1; month <= contractTerm; month++) {
      const monthPayments = monthPaymentsMap.get(month) || [];
      totalPaidCalc += monthPayments.reduce((sum, p) => sum + Number(p.amount), 0);
    }
    const currentRemainingDebt = Math.max(0, totalAmount - prepayment - totalPaidCalc);

    setPaymentSchedule(schedule);
    setTotalPaid(totalPaidCalc);
    setRemainingDebt(currentRemainingDebt);
    // Берем как ежемесячный платеж сумму последнего месяца графика
    setMonthlyPayment(schedule.length > 0 ? schedule[schedule.length - 1].initialAmount : (totalAmount - prepayment) / contractTerm);
  };

  const handleFullPayment = async (monthIndex) => {
    const payment = paymentSchedule[monthIndex];
    if (!payment || payment.status === 'Оплачено' || isLoading) return;

    setSelectedPayment(payment);
    setPartialAmount(payment.remainingForMonth.toString()); // Автоподстановка полной суммы
    setActualDate(today);
    setOffice('');
    setShowFullPaymentDialog(true); // Всегда показываем диалог
  };

  const processPayment = async (isPartial = false) => {
    if (!selectedPayment || isLoading) return;

    const amount = parseFloat(partialAmount);
    if (isNaN(amount) || amount <= 0) {
      alert('Введите корректную сумму');
      return;
    }

    if (!actualDate) {
      alert('Укажите дату платежа');
      return;
    }

    if (!office) {
      alert('Выберите офис оплаты');
      return;
    }

    setIsLoading(true);
    try {
      const paymentData = {
        clientId: client.id,
        month: selectedPayment.month,
        date: selectedPayment.date,
        actualDate: new Date(actualDate).toISOString(),
        initialAmount: selectedPayment.initialAmount,
        amount: amount,
        office: office,
        status: isPartial ? 'partial' : 'paid'
      };

      await addPayment(paymentData);
      setShowFullPaymentDialog(false);
      setShowPartialPaymentDialog(false);
      window.location.reload();
    } catch (error) {
      console.error('Ошибка платежа:', error);
      alert(`Ошибка: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const openPartialPaymentDialog = (monthIndex) => {
    const payment = paymentSchedule[monthIndex];
    setSelectedPayment(payment);
    setPartialAmount('');
    if (!client.paymentType?.includes('Рассрочка')) {
      setActualDate(today);
    }
    setShowPartialPaymentDialog(true);
  };

  const handlePartialPayment = async () => {
    if (!selectedPayment || isLoading) return;

    const amount = parseFloat(partialAmount);
    if (isNaN(amount) || amount <= 0) {
      alert('Введите корректную сумму');
      return;
    }

    if (!actualDate) {
      alert('Укажите дату платежа');
      return;
    }

    if (!office) {
      alert('Выберите офис оплаты');
      return;
    }

    setIsLoading(true);
    try {
      const paymentData = {
        clientId: client.id,
        month: selectedPayment.month,
        date: selectedPayment.date,
        actualDate: new Date(actualDate).toISOString(),
        initialAmount: selectedPayment.initialAmount,
        amount: amount,
        office: office,
        status: 'partial' // Всегда ставим partial, система сама определит полную оплату
      };

      await addPayment(paymentData);
      setShowPartialPaymentDialog(false);
      window.location.reload();
    } catch (error) {
      console.error('Ошибка при обработке платежа:', error);
      alert('Произошла ошибка: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="payment-calculator-root">
      <div className="pc-container">
        <div className="pc-summary">
          <h2 className="pc-summary-title">Информация о платежах</h2>
          <div className="pc-summary-content">
            <p className="pc-summary-row">
              <span>Сумма договора:</span>
              <span>{client?.contractAmount?.toFixed(2) || 0} ₽</span>
            </p>
            <p className="pc-summary-row">
              <span>Предоплата:</span>
              <span>{client?.prepaymentAmount?.toFixed(2) || 0} ₽</span>
            </p>
            <p className="pc-summary-row">
              <span>Всего оплачено:</span>
              <span>{totalPaid.toFixed(2)} ₽</span>
            </p>
            <p className="pc-summary-row">
              <span>Остаток долга:</span>
              <span>{remainingDebt.toFixed(2)} ₽</span>
            </p>
            <p className="pc-summary-row">
              <span>Ежемесячный платеж:</span>
              <span>{monthlyPayment.toFixed(2)} ₽</span>
            </p>
          </div>
        </div>

        <h3 className="pc-section-title">График платежей</h3>
        {paymentSchedule.length > 0 ? (
          <div className="pc-table-wrapper">
            <table className="pc-table">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>Месяц</th>
                  <th style={{ width: '10%' }}>Дата платежа</th>
                  <th style={{ width: '10%' }}>Факт. дата</th>
                  <th style={{ width: '10%' }}>Сумма</th>
                  <th style={{ width: '10%' }}>Оплачено</th>
                  <th style={{ width: '10%' }}>Остаток</th>
                  <th style={{ width: '12%' }}>Статус</th>
                  <th style={{ width: '12%' }}>Офис</th>
                  <th style={{ width: '11%' }}>Способ оплаты</th>
                  <th style={{ width: '10%', minWidth: '150px' }}>Действия</th>
                </tr>
              </thead>
              <tbody>
                {paymentSchedule.map((payment, index) => (
                  <tr key={index}>
                    <td>{payment.month}</td>
                    <td>{new Date(payment.date).toLocaleDateString()}</td>
                    <td>{payment.actualDate ? new Date(payment.actualDate).toLocaleDateString() : '—'}</td>
                    <td>{payment.initialAmount.toFixed(2)} ₽</td>
                    <td>{payment.paidAmount.toFixed(2)} ₽</td>
                    <td>{payment.remainingForMonth.toFixed(2)} ₽</td>
                    <td>
                      <span className={`pc-status ${
                        payment.status === 'Оплачено'
                          ? 'pc-status-paid'
                          : payment.status === 'Частично оплачено'
                          ? 'pc-status-partial'
                          : 'pc-status-unpaid'
                      }`}>
                        {payment.status}
                      </span>
                    </td>
                    <td>{payment.office}</td>
                    <td>{client.paymentType}</td>
                    <td>
                      {payment.status !== 'Оплачено' && (
                        <div className="pc-actions">
                          <button
                            onClick={() => handleFullPayment(index)}
                            className="pc-btn pc-btn-full"
                            disabled={isLoading}
                          >
                            {isLoading ? 'Обработка...' : 'Полностью'}
                          </button>
                          <button
                            onClick={() => openPartialPaymentDialog(index)}
                            className="pc-btn pc-btn-partial"
                            disabled={isLoading}
                          >
                            {isLoading ? 'Обработка...' : 'Частично'}
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="pc-empty-message">График платежей недоступен</p>
        )}

        {/* Диалог полной оплаты */}
        {(showFullPaymentDialog || showPartialPaymentDialog) && selectedPayment && (
          <div className="pc-modal" onClick={() => {
            setShowFullPaymentDialog(false);
            setShowPartialPaymentDialog(false);
          }}>
            <div className="pc-modal-content" onClick={e => e.stopPropagation()}>
              <h3 className="pc-modal-title">
                {showFullPaymentDialog ? 'Полная оплата' : 'Частичная оплата'}
              </h3>

              <div className="pc-modal-grid">
                <div className="pc-modal-row">
                  <span>Месяц:</span>
                  <span>{selectedPayment.month}</span>
                </div>

                <div className="pc-modal-row">
                  <span>Сумма к оплате:</span>
                  <span>{selectedPayment.remainingForMonth.toFixed(2)} ₽</span>
                </div>

                <div className="pc-form-group">
                  <label className="pc-form-label">
                    {showFullPaymentDialog ? 'Сумма оплаты' : 'Сумма частичной оплаты'}:
                  </label>
                  <input
                    type="number"
                    value={partialAmount}
                    onChange={(e) => setPartialAmount(e.target.value)}
                    min="0.01"
                    max={selectedPayment.remainingForMonth}
                    step="0.01"
                    className="pc-form-input"
                  />
                </div>

                <div className="pc-form-group">
                  <label className="pc-form-label">Дата платежа:</label>
                  <input
                    type="date"
                    value={actualDate}
                    onChange={(e) => setActualDate(e.target.value)}
                    className="pc-form-input"
                    max={today}
                    required
                  />
                </div>

                <div className="pc-form-group">
                  <label className="pc-form-label">Офис оплаты:</label>
                  <select
                    value={office}
                    onChange={(e) => setOffice(e.target.value)}
                    className="pc-form-input"
                    required
                  >
                    <option value="">Выберите офис</option>
                    {offices.map((office, idx) => (
                      <option key={idx} value={office}>{office}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="pc-modal-actions">
                <button 
                  onClick={() => processPayment(!showFullPaymentDialog)}
                  className="pc-modal-btn pc-modal-btn-primary"
                  disabled={isLoading || !partialAmount || !actualDate || !office}
                >
                  {isLoading ? 'Обработка...' : 'Подтвердить'}
                </button>
                <button 
                  onClick={() => {
                    setShowFullPaymentDialog(false);
                    setShowPartialPaymentDialog(false);
                  }}
                  className="pc-modal-btn pc-modal-btn-secondary"
                >
                  Отмена
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Диалог частичной оплаты */}
        {showPartialPaymentDialog && selectedPayment && (
          <div className="pc-modal" onClick={() => setShowPartialPaymentDialog(false)}>
            <div className="pc-modal-content" onClick={e => e.stopPropagation()}>
              <h3 className="pc-modal-title">Частичная оплата</h3>

              <div className="pc-modal-grid">
                <div className="pc-modal-row">
                  <span>Месяц:</span>
                  <span>{selectedPayment.month}</span>
                </div>
                <div className="pc-modal-row">
                  <span>Полная сумма:</span>
                  <span>{selectedPayment.initialAmount.toFixed(2)} ₽</span>
                </div>
                <div className="pc-modal-row">
                  <span>Уже оплачено:</span>
                  <span>{selectedPayment.paidAmount.toFixed(2)} ₽</span>
                </div>
                <div className="pc-modal-row">
                  <span>Остаток:</span>
                  <span>{selectedPayment.remainingForMonth.toFixed(2)} ₽</span>
                </div>

                <div className="pc-form-group">
                  <label className="pc-form-label">Сумма оплаты:</label>
                  <input
                    type="number"
                    value={partialAmount}
                    onChange={(e) => setPartialAmount(e.target.value)}
                    min="0.01"
                    step="0.01"
                    className="pc-form-input"
                    placeholder={`Рекомендуемая: ${selectedPayment.remainingForMonth.toFixed(2)} ₽`}
                  />
                  {partialAmount && parseFloat(partialAmount) <= 0 && (
                    <div className="pc-error">Сумма должна быть больше 0</div>
                  )}
                  {partialAmount && parseFloat(partialAmount) > selectedPayment.remainingForMonth && (
                    <div className="pc-warning">
                      Будет зачтена переплата в размере {(parseFloat(partialAmount) - selectedPayment.remainingForMonth).toFixed(2)} ₽
                    </div>
                  )}
                </div>

                <div className="pc-form-group">
                  <label className="pc-form-label">Дата платежа:</label>
                  <input
                    type="date"
                    value={actualDate}
                    onChange={(e) => setActualDate(e.target.value)}
                    className="pc-form-input"
                    max={today}
                    required
                  />
                  {!actualDate && (
                    <div className="pc-error">Укажите дату платежа</div>
                  )}
                </div>

                <div className="pc-form-group">
                  <label className="pc-form-label">Офис оплаты:</label>
                  <select
                    value={office}
                    onChange={(e) => setOffice(e.target.value)}
                    className="pc-form-input"
                    required
                  >
                    <option value="">Выберите офис</option>
                    {offices.map((officeItem, idx) => (
                      <option key={idx} value={officeItem}>{officeItem}</option>
                    ))}
                  </select>
                  {!office && (
                    <div className="pc-error">Выберите офис</div>
                  )}
                </div>
              </div>

              <div className="pc-modal-actions">
                <button 
                  onClick={() => {
                    if (!office) {
                      alert('Выберите офис оплаты!');
                      return;
                    }
                    if (!actualDate) {
                      alert('Укажите дату платежа!');
                      return;
                    }
                    handlePartialPayment();
                  }}
                  className="pc-modal-btn pc-modal-btn-primary"
                  disabled={isLoading || !partialAmount || parseFloat(partialAmount) <= 0}
                >
                  {isLoading ? 'Обработка...' : 'Подтвердить'}
                </button>
                <button 
                  onClick={() => setShowPartialPaymentDialog(false)}
                  className="pc-modal-btn pc-modal-btn-secondary"
                >
                  Отмена
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentCalculator;
